import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Joy`}</h1>
    <h4>{`June 20–25, 2010 | Vinalhaven, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGankMDyLEP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEhNB/9oACAEBAAEFAnjeJ7OWeq5Gf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGxAAAQQDAAAAAAAAAAAAAAAAEQACEDESIkH/2gAIAQEABj8CYBa5iqjYx//EABoQAQACAwEAAAAAAAAAAAAAAAEAESFBUTH/2gAIAQEAAT8hTJ9IJxXhPZaumcxQDuE3Ndiuf//aAAwDAQACAAMAAAAQK/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EEDs/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EApk/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFRYf/aAAgBAQABPxBkASAKx9lAdhSX9RllGWnIaEPVRBojCU28ls7hU//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Joy",
          "title": "Joy",
          "src": "/static/a59b405f58c4fb5d415b1d6694a93111/80e3c/joy.jpg",
          "srcSet": ["/static/a59b405f58c4fb5d415b1d6694a93111/f93b5/joy.jpg 300w", "/static/a59b405f58c4fb5d415b1d6694a93111/b4294/joy.jpg 600w", "/static/a59b405f58c4fb5d415b1d6694a93111/80e3c/joy.jpg 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://www.core77.com/posts/16533/driven-to-joy-by-gabrielle-esperdy-16533" title="Driven to Joy by Gabrielle Esperdy" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/joy/" title="BitterSweet by Margo Halverson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://www.youtube.com/watch?v=m6nYiZ914Yw" title="Joy by DesignInquiry" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/love-work/" title="Love & Joy by Eve Faulkes" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/precis-for-a-field-guide-to-joy/" title="Précis for a Field Guide to Joy by Gabrielle Esperdy" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-lens/" title="The Lens by Jeremy Moser" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-sentient-and-the-bag-of-meat-2/" title="The Sentient & the Bag of Meat by Elliott Earls" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/bread/" title="Bread by Charles Melcher" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/joy-2/" title="Joy by Denise Gonzales Crisp" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/learn-from-snowstorms/" title="Learn from Snowstorms by Alex Gilliam" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/trophyheads-joy/" title="trophyheads by DesignInquiry" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/alloo-mutter/" title="Alloo Mutter by DesignInquiry" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`“Surely joy is the condition of life.”- Henry David Thoreau, Philosopher (1842)`}</strong></p>
    <p><strong parentName="p">{`“The absence of joy is the biggest threat to our society.”- Will Alsop, Architect (2003)`}</strong></p>
    <p><strong parentName="p">{`Does design create or embody joy, or does it merely create conditions for joy to emerge? Is there joy in the designed artifact or in the act of design? Is a designer’s joy the same as a user’s joy? What is the relationship between joy and play and how might design conjoin the two? What might a taxonomy of joy look like?`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      